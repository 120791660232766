<template>
  <v-row>
    <v-col cols="12">
      <div class="text-h5 mb-5">Typy użytkownika: {{ user.login }}</div>
      <div>
        <h3 v-if="!competitions[0] && !competitionsLoading">
          Brak typów do wyświetlenia
        </h3>
        <v-select
          v-else
          label="Rozgrywki"
          dense
          outlined
          multiple
          v-model="competitionsSelected"
          :items="competitions"
          item-text="competitionsFullName"
          item-value="competitionsId"
          return-object
          chips
          deletable-chips
          small-chips
          :loading="competitionsLoading"
        ></v-select>
      </div>
      <BookmakerBets
        v-for="comps in competitionsSelected"
        :key="comps.competitionsId"
        class="mb-10"
        :competitions="comps"
        :betRecords="GetBetRecords(comps.competitionsId)"
        :saveButtonVisible="false"
      />
    </v-col>
  </v-row>
</template>

<script>
import UserService from "@/services/UserService.js";
import BookmakerService from "@/services/BookmakerService.js";
import BookmakerBets from "@/components/bookmaker/BookmakerBets.vue";
import dayjs from "@/plugins/dayjs.js";
import { mapGetters, mapActions } from "vuex";

export default {
  name: "SpecificUserBetsView",
  props: {
    leagueId: {
      type: String,
      require: true,
    },
    userId: {
      type: String,
      require: true,
    },
  },
  components: {
    BookmakerBets,
  },
  data() {
    return {
      user: {},
      competitions: [],
      competitionsSelected: [],
      bookmakerMatches: [],
      competitionsLoading: true,
    };
  },
  async mounted() {
    await this.$_setUserData();
    await this.setCompetitions();
    await this.$_setBookmakerBetsItems();
    this.competitions = this.$_getLeagueCompetitions();
    this.competitionsSelected = this.competitions;
  },
  // TODO : WATCH competitionsSelected - sortowanie
  methods: {
    ...mapGetters("common", ["getCompetitions"]),
    ...mapActions("common", ["setCompetitions"]),
    GetBetRecords(competitionsId) {
      return this.bookmakerMatches
        .filter((x) => x.competitionsId === competitionsId)
        .map((x) => x.userBets)
        .flat();
    },
    async $_setUserData() {
      try {
        const response = await UserService.GetUser(this.userId);
        const result = response.data;
        if (result.success) {
          this.user = result.data;
        }
      } catch (err) {
        //
      }
    },
    async $_setBookmakerBetsItems() {
      try {
        this.competitionsLoading = true;
        const response = await BookmakerService.GetUserBetsForLeague(
          this.leagueId,
          this.userId
        );

        const result = response.data;
        if (result.success) {
          this.bookmakerMatches = result.data.map((x) => {
            x.userBets.map((b) => {
              b.match = {
                ...b.match,
                matchDate: this.$_getMatchDate(b.match.matchDateTime),
                matchDateFriendly: this.$_getMatchDateFriendly(
                  b.match.matchDateTime
                ),
                matchTime: this.$_getMatchTime(b.match.matchDateTime),
              };
            });
            return x;
          });
        }
      } catch (err) {
        //
      } finally {
        this.competitionsLoading = false;
      }
    },
    $_getLeagueCompetitions() {
      const ids = this.bookmakerMatches.map((x) =>
        x.competitionsId.toUpperCase()
      );

      const leagueComps = this.getCompetitions().filter((x) =>
        ids.includes(x.competitionsId.toUpperCase())
      );

      return leagueComps;
    },
    $_getMatchDate(dateTime) {
      return dayjs(dateTime).format("YYYY MMMM DD");
    },
    $_getMatchDateFriendly(dateTime) {
      return dayjs(dateTime).format("DD MMMM YYYY, dddd");
    },
    $_getMatchTime(dateTime) {
      return dayjs(dateTime).format("HH:mm");
    },
  },
};
</script>
