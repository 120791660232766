<template>
  <v-row>
    <v-col cols="12">
      <div class="text-h5 mb-5">
        Ranking - {{ leagueName }}
        <v-tooltip bottom>
          <template v-slot:activator="{ on, attrs }">
            <v-icon v-bind="attrs" v-on="on" class="ml-1">
              fas fa-info-circle
            </v-icon>
          </template>
          <span> Data utworzenia: {{ leagueInsertDate }} </span>
          <br />
          <span> Właściciel: {{ leagueInsertUser }} </span>
          <br />
          <span> Punktacja: </span>
          <br />
          <span>
            - prawidłowy dokładny wynik: {{ leagueCorrectResultPoints }}
          </span>
          <br />
          <span>
            - prawidłowy typ (bez dokładnego wyniku):
            {{ leagueCorrectBetPoints }}
          </span>
        </v-tooltip>
      </div>
      <v-data-table
        :headers="headers"
        :items="items"
        :loading="loading"
        hide-default-footer
        disable-sort
        class="elevation-1"
        :items-per-page="-1"
        mobile-breakpoint="0"
      >
        <template v-slot:[`item.userBets`]="{ item }">
          <v-btn @click="ShowSpecificUserBets(item)" icon>
            <v-icon>fas fa-futbol</v-icon>
          </v-btn>
        </template>
      </v-data-table>
    </v-col>
  </v-row>
</template>

<script>
import BookmakerService from "@/services/BookmakerService";
import dayjs from "@/plugins/dayjs.js";

export default {
  name: "BookmakerLeagueView",
  props: {
    leagueId: {
      type: String,
    },
  },
  data() {
    return {
      headers: [
        { text: "Pozycja", value: "userRanking", width: "10%" },
        { text: "Gracz", value: "userLogin", width: "40%" },
        { text: "Punkty", value: "userPoints", width: "15%" },
        { text: "Trafione typy", value: "userCorrectBets", width: "15%" },
        { text: "Trafione wyniki", value: "userCorrectResults", width: "15%" },
        { text: "Typy", value: "userBets", width: "5%", align: "end" },
      ],
      leagueName: "",
      leagueInsertDate: "",
      leagueInsertUser: "",
      leagueCorrectBetPoints: "",
      leagueCorrectResultPoints: "",
      items: [],
      loading: false,
    };
  },
  async mounted() {
    await this.SetLeagueData();
  },
  methods: {
    async SetLeagueData() {
      try {
        this.loading = true;
        const response = await BookmakerService.GetLeagueRank(this.leagueId);

        if (response.status === 200 && !(response.data || {}).errors[0]) {
          const data = response.data.data;
          this.leagueName = data.leagueName;
          this.leagueInsertDate = dayjs(data.leagueInsertDate).format(
            "DD MMMM YYYY HH:mm"
          );
          this.leagueInsertUser = data.leagueInsertUser;
          this.leagueCorrectBetPoints = data.leagueCorrectBetPoints;
          this.leagueCorrectResultPoints = data.leagueCorrectResultPoints;
          this.items = data.users;
        } else if (response.status === 404) {
          this.$router
            .push({
              name: "BookmakerLeagues",
            })
            .catch(() => {});
        }
      } catch (err) {
        return;
      } finally {
        this.loading = false;
      }
    },
    async ShowSpecificUserBets(row) {
      this.$router
        .push({
          name: "SpecificUserBets",
          params: { leagueId: this.leagueId, userId: row.userId },
        })
        .catch(() => {});
    },
  },
};
</script>
