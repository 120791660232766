<template>
  <div>
    <h3 class="mb-6">Witaj w typerze piłkarskim Golden League!</h3>
    <p>
      Typuj mecze w wybranych ligach i rywalizuj ze znajomymi lub innymi
      graczami Golden League.
    </p>
    <p v-if="!isAuthorized()">
      Aby wziąć udział w zabawie, musisz utworzyć konto w zakładce
      "Rejestracja". Jeżeli masz już konto zaloguj się do aplikacji w zakładce
      "Zaloguj".
    </p>
    <p>
      Utwórz własną ligę i zaproś do niej znajomych, bądź dołącz do już
      istniejącej ligi. Tworząc własną ligę sam decydujesz, które rozgrywki
      piłkarskie będą w niej typowane. Jeśli dołączysz do ligi utworzonej przez
      innego użytkownika, będziesz mógł typować mecze w rozgrywkach, które ten
      użytkownik wskazał. Możesz oczywiście brać udział w więcej niż jednej
      lidze.
    </p>
    <p>
      Aby utworzyć własną ligę przejdź do zakładki "Ligi typerów" i kliknij w
      przycisk "Utwórz ligę". Po utworzeniu ligi zaproś do niej innych
      użytkowników udostępniając im kod ligi. Kod ligi możesz skopiować na
      ekranie "Ligi typerów", rozwijając menu kontekstowe danej ligi.
    </p>
    <p>
      Aby dołączyć do ligi utworzonej przez innego użytkownika musisz znać kod
      ligi. Gdy znasz już kod ligi, do której chcesz dołączyć, przejdź do
      zakładki "Ligi typerów" i kliknij w przycisk "Dołącz do ligi".
      <br />
      <b>
        Możesz dołączyć do testowej ligi, w której można typować mecze
        Ekstraklasy, Premier League i Ligi Mistrzów, używając poniższego kodu:
        <br />
        EB339C96-5A25-4B65-A842-BBF6A3B0CA3B
      </b>
    </p>
    <p>
      Punkty w danej lidze są liczone za wytypowane wyniki tylko w tych
      rozgrywkach, które zostały zaznaczone na etapie tworzenia ligi. Jeżeli
      liga zostanie utworzona w terminie późniejszym niż rozpoczną się wybrane
      rozgrywki, to punkty w lidze są liczone z pominięciem meczów rozpoczętych
      przed utworzeniem ligi.
    </p>
    <p>
      Punkty w danej lidze są liczone za wytypowane wyniki tylko w tych
      rozgrywkach, które zostały zaznaczone na etapie tworzenia ligi. Jeżeli
      liga zostanie utworzona w terminie późniejszym niż rozpoczną się wybrane
      rozgrywki, to punkty w lidze są liczone z pominięciem meczów rozpoczętych
      przed utworzeniem ligi.
    </p>
    <p>
      Aby sprawdzić ranking danej ligi, przejdź do zakładki "Ligi typerów" i
      kliknij w rekord danej ligi w tabeli "Ligi prywatne".
    </p>
    <p>
      Aby typować mecze, przejdź do zakładki "Typer". Z listy rozwijalnej
      wybierz rozgrywki, które chcesz typować. Typować możesz tylko te
      rozgrywki, które są wskazane do typowania w ligach, w których
      uczestniczysz.
      <br />
      <b>
        UWAGA: Typowanie danego meczu jest możliwe maksymalnie do godziny przed
        jego rozpoczęciem! Ilość punktów za prawidłowo wskazanego zwycięzcę lub
        pełny wynik meczu ustala właściciel prywatnej ligi podczas jej
        tworzenia. Ustaloną punktację można podejrzeć na ekranie rankingu danej
        ligi poprzez najechanie na ikonkę "i".
      </b>
    </p>
    <p>
      Po podliczeniu punktów Twoje typy zostaną odpowiednio oznaczone. Zielony
      kolor oznacza trafiony dokładny wynik, żółty trafiony tylko prawidłowy
      typ, czerwony nietrafiony typ. Brak koloru oznacza, że mecz nie został
      jeszcze rozegrany lub punkty nie zostały jeszcze podliczone.
    </p>
    <p class="mt-16" style="font-style: italic">
      Obecna wersja aplikacji jest wersją próbną.<br />
      Wszelkie błędy znalezione w aplikacji proszę kierować na maila:
      <a href="mailto:admin@golden-league.agajewski.pl">
        admin@golden-league.agajewski.pl </a
      >.<br />
      Docelowo aplikacja będzie dostępna w pełnej wersji od sezonu 2023/24.
    </p>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
export default {
  name: "InstructionView",
  methods: {
    ...mapGetters("user", ["isAuthorized"]),
  },
};
</script>

<style lang="scss" scoped>
.v-application a {
  color: var(--v-info-darken3);
}
</style>
