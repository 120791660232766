<template>
  <div>
    <v-card>
      <v-row>
        <v-col class="d-flex flex-column" cols="12" sm="6" lg="3">
          <v-select
            dense
            outlined
            :label="$t('common.gameweek')"
            :items="gameweeks"
            v-model="gameweekName"
          ></v-select>
        </v-col>
        <v-spacer></v-spacer>
        <div class="d-flex flex-column text-center pa-3">
          <v-chip label large>
            {{ competitions.competitionsFullName }}
          </v-chip>
        </div>
      </v-row>
      <v-divider class="mt-3"></v-divider>

      <!-- DESKTOP -->
      <v-data-table
        v-if="!isMobile"
        :headers="matchesTable.headers.desktop"
        :items="gameweekItems"
        :items-per-page="20"
        :loading="matchesTable.loading"
        hide-default-footer
        group-by="match.matchDate"
        sort-by="match.matchDateTime"
        disable-sort
        :item-class="RowClass"
        class="elevation-1"
        no-data-text="Brak typów do wyświetlenia"
      >
        <template v-slot:[`group.header`]="{ items }">
          <th colspan="7">
            {{ items[0].match.matchDateFriendly }}
          </th>
        </template>
        <template v-slot:[`item.matchTime`]="{ item }">
          {{ item.match.matchTime }}
        </template>
        <template v-slot:[`item.homeTeamName`]="{ item }">
          {{ item.match.homeTeam.teamName }}
        </template>
        <template v-slot:[`item.awayTeamName`]="{ item }">
          {{ item.match.awayTeam.teamName }}
        </template>
        <template v-slot:[`item.teamsSpacer`]> - </template>
        <template v-slot:[`item.resultBet`]="{ item }">
          <div v-if="item.betAllowed">
            <div
              class="d-flex"
              style="align-items: baseline; justify-content: center"
            >
              <div class="flex" style="width: 35px">
                <v-text-field
                  dense
                  outlined
                  hide-details
                  v-mask="'#'"
                  v-model.number="item.matchResultBet.homeTeamScoreBet"
                ></v-text-field>
              </div>
              <div class="flex mx-1">
                <span>:</span>
              </div>
              <div class="flex" style="width: 35px">
                <v-text-field
                  dense
                  outlined
                  hide-details
                  v-mask="'#'"
                  v-model.number="item.matchResultBet.awayTeamScoreBet"
                ></v-text-field>
              </div>
            </div>
          </div>
          <div v-else>
            <span v-if="!BetEmpty(item)">
              {{ item.matchResultBet.homeTeamScoreBet }} :
              {{ item.matchResultBet.awayTeamScoreBet }}
            </span>
            <v-icon small class="bet-result-icon ml-1">
              {{ BetResultIcon(item) }}
            </v-icon>
          </div>
        </template>
        <template v-slot:[`item.resultActual`]="{ item }">
          <div v-if="MatchInProgress(item.match)">
            <v-progress-circular indeterminate size="20"></v-progress-circular>
          </div>
          <span v-else-if="MatchFinished(item.match)">
            {{ item.match.homeTeamScore }} :
            {{ item.match.awayTeamScore }}
          </span>
          <v-tooltip v-else-if="MatchPostponed(item.match)" bottom>
            <template v-slot:activator="{ on, attrs }">
              <span v-bind="attrs" v-on="on"> przeł. </span>
            </template>
            <span> mecz został przełożony na inny termin </span>
          </v-tooltip>
          <span v-else> : </span>
        </template>
        <template v-if="saveButtonVisible" v-slot:[`body.append`]>
          <tr>
            <td colspan="12" class="text-right">
              <v-btn
                class="primary-gradient-bg my-5"
                outlined
                :loading="saveLoading"
                :disabled="saveLoading"
                @click="SaveBookmakerBets"
                >{{ $t("bookmakerBets.saveYourBets") }}</v-btn
              >
            </td>
          </tr>
        </template>
      </v-data-table>

      <!-- MOBILE -->
      <v-data-table
        v-if="isMobile"
        :headers="matchesTable.headers.mobile"
        :items="gameweekItems"
        :items-per-page="20"
        :loading="matchesTable.loading"
        hide-default-footer
        group-by="match.matchDate"
        sort-by="match.matchDateTime"
        disable-sort
        :item-class="RowClass"
        class="elevation-1"
        no-data-text="Brak typów do wyświetlenia"
      >
        <template v-slot:[`group.header`]="{ items }">
          <th colspan="7">
            {{ items[0].match.matchDateFriendly }}
          </th>
        </template>
        <template v-slot:[`item.matchTime`]="{ item }">
          {{ item.match.matchTime }}
        </template>
        <template v-slot:[`item.teams`]="{ item }">
          {{ item.match.homeTeam.teamName }} -
          {{ item.match.awayTeam.teamName }}
        </template>
        <template v-slot:[`item.resultBet`]="{ item }">
          <div v-if="item.betAllowed">
            <div
              class="d-flex"
              style="align-items: baseline; justify-content: end"
            >
              <div class="flex" style="width: 35px">
                <v-text-field
                  dense
                  outlined
                  hide-details
                  v-mask="'#'"
                  v-model.number="item.matchResultBet.homeTeamScoreBet"
                ></v-text-field>
              </div>
              <div>
                <span class="mx-1">:</span>
              </div>
              <div class="flex" style="width: 35px">
                <v-text-field
                  dense
                  outlined
                  hide-details
                  v-mask="'#'"
                  v-model.number="item.matchResultBet.awayTeamScoreBet"
                ></v-text-field>
              </div>
            </div>
          </div>
          <div v-else>
            <v-icon small class="bet-result-icon mr-1">
              {{ BetResultIcon(item) }}
            </v-icon>
            <span v-if="!BetEmpty(item)">
              {{ item.matchResultBet.homeTeamScoreBet }} :
              {{ item.matchResultBet.awayTeamScoreBet }}
            </span>
          </div>
        </template>
        <template v-slot:[`item.resultActual`]="{ item }">
          <div v-if="MatchInProgress(item.match)">
            <v-progress-circular indeterminate size="20"></v-progress-circular>
          </div>
          <span v-else-if="MatchFinished(item.match)">
            {{ item.match.homeTeamScore }} :
            {{ item.match.awayTeamScore }}
          </span>
          <v-tooltip v-else-if="MatchPostponed(item.match)" bottom>
            <template v-slot:activator="{ on, attrs }">
              <span v-bind="attrs" v-on="on"> przeł. </span>
            </template>
            <span> mecz został przełożony na inny termin </span>
          </v-tooltip>
        </template>
        <template v-if="saveButtonVisible" v-slot:[`body.append`]>
          <tr>
            <td colspan="12" class="d-flex justify-end" style="height: auto">
              <v-btn
                class="primary-gradient-bg my-5"
                outlined
                :loading="saveLoading"
                :disabled="saveLoading"
                @click="SaveBookmakerBets"
                >{{ $t("bookmakerBets.saveYourBets") }}</v-btn
              >
            </td>
          </tr>
        </template>
      </v-data-table>
    </v-card>
  </div>
</template>

<script>
import UserService from "@/services/UserService.js";
import dayjs from "@/plugins/dayjs.js";

export default {
  name: "BookmakerBets",
  props: {
    competitions: {
      type: Object,
      require: true,
    },
    betRecords: {
      type: Array,
      default: () => [],
    },
    saveButtonVisible: {
      type: Boolean,
      default: true,
    },
  },
  data() {
    return {
      matchesTable: {
        headers: {
          desktop: [
            { value: "matchTime", width: "67px" },
            { value: "homeTeamName", align: "end" },
            { value: "teamsSpacer", align: "center", width: "1%" },
            { value: "awayTeamName", align: "start" },
            { value: "resultBet", text: "Typ", align: "center", width: "82px" },
            {
              value: "resultActual",
              text: "Wynik",
              align: "center",
              width: "65px",
            },
          ],
          mobile: [
            { value: "matchTime" },
            { value: "teams", align: "end" },
            { value: "resultBet", text: "Typ", align: "center" },
            {
              value: "resultActual",
              text: "Wynik",
              align: "center",
            },
          ],
        },
        loading: false,
      },
      gameweekName: "",
      saveLoading: false,
    };
  },
  computed: {
    gameweekItems() {
      return this.betRecords
        .filter((x) => x.match.gameweekName == this.gameweekName)
        .map((x) =>
          Object.assign({}, x, {
            betAllowed: this.AllowBet(x.match.matchDateTime),
          })
        );
    },
    gameweeks() {
      return [
        ...new Set(this.betRecords.map((x) => x.match.gameweekName)),
      ].sort((a, b) => a - b);
    },
  },
  mounted() {
    this.$_setCurrentGameweek();
  },
  methods: {
    AllowBet(matchDateTime) {
      // pozwól typować najpóźniej godzinę przed meczem
      return dayjs(matchDateTime) > dayjs().add(1, "hour");
    },
    BetEmpty(item) {
      return (
        (item.matchResultBet || {}).homeTeamScoreBet == null ||
        (item.matchResultBet || {}).awayTeamScoreBet == null
      );
    },
    MatchInProgress(match) {
      return (
        match.statusCode === "IN_PROGRESS" ||
        (match.statusCode === "NOT_STARTED" &&
          dayjs() > dayjs(match.matchDateTime))
      );
    },
    MatchFinished(match) {
      return match.statusCode === "FINISHED";
    },
    MatchPostponed(match) {
      return match.statusCode === "POSTPONED";
    },
    RowClass(item) {
      if (item.matchResultBet.betResult == 2) {
        return "betting-hit";
      }
      if (item.matchResultBet.betResult == 1) {
        return "betting-partially-hit";
      }
      if (item.matchResultBet.betResult == 0) {
        return "betting-missed";
      }
      if (!item.matchResultBet.betResult) {
        return "betting-empty";
      }

      return "";
    },
    BetResultIcon(item) {
      if (item.matchResultBet.betResult == 2) {
        return "fas fa-check-circle";
      }
      if (item.matchResultBet.betResult == 1) {
        return "fas fa-check-circle";
      }
      if (item.matchResultBet.betResult == 0) {
        return "fas fa-times-circle";
      }

      return "";
    },
    async SaveBookmakerBets() {
      try {
        this.saveLoading = true;
        const dto = this.$_getDto();
        if (!this.$_isValid(dto)) {
          return;
        }

        const response = await UserService.UpdateBookmakerBets(dto);
        if (response.status === 200) {
          if ((response.data || {}).data === true) {
            this.$vToastify.customSuccess(
              `Zapisano typy dla rozgrywek: ${this.competitions.competitionsFullName}, kolejka: ${this.gameweekName}`
            );
          }

          if ((response.data || {}).errors[0]) {
            (response.data || {}).errors.forEach((err) =>
              this.$vToastify.customWarning(err)
            );
          }
          this.$emit("bets-saved", this.competitions);
        }
      } catch (err) {
        //
      } finally {
        this.saveLoading = false;
      }
    },
    $_isValid() {
      // $_isValid(dto) {
      // const anyBetInserted = dto.some(
      //   (x) =>
      //     (x.matchResultBet.homeTeamScoreBet ||
      //       x.matchResultBet.homeTeamScoreBet == 0) &&
      //     (x.matchResultBet.awayTeamScoreBet ||
      //       x.matchResultBet.awayTeamScoreBet == 0)
      // );
      // if (!anyBetInserted) {
      //   this.$vToastify.validationError(
      //     "Aby zapisać zmiany musisz wprowadzić typ przynajmniej dla jednego meczu"
      //   );
      //   return false;
      // }

      return true;
    },
    $_setCurrentGameweek() {
      const orderedRecords = (this.betRecords || [])
        .map((x) => x.match)
        .sort((a, b) => dayjs(a.matchDateTime) - dayjs(b.matchDateTime));

      const closestMatchToBet = orderedRecords.find(
        (x) => dayjs(x.matchDateTime) > dayjs().startOf("day")
      );

      if (closestMatchToBet) {
        this.gameweekName = closestMatchToBet.gameweekName;
      } else {
        this.gameweekName = (
          orderedRecords[orderedRecords.length - 1] || {}
        ).gameweekName;
      }
    },
    $_getDto() {
      return this.gameweekItems
        .filter((x) => x.betAllowed)
        .map((x) => {
          if (x.matchResultBet.homeTeamScoreBet === "") {
            x.matchResultBet.homeTeamScoreBet = null;
          }
          if (x.matchResultBet.awayTeamScoreBet === "") {
            x.matchResultBet.awayTeamScoreBet = null;
          }
          return x;
        });
    },
  },
};
</script>

<style>
.betting-hit i.bet-result-icon {
  color: #71fb00 !important;
}
.betting-partially-hit i.bet-result-icon {
  color: #ffcc00 !important;
}
.betting-missed i.bet-result-icon {
  color: #fb4040 !important;
}
</style>
