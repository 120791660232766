<template>
  <v-row justify="center">
    <v-col cols="12" md="6" lg="4">
      <div>
        <v-card>
          <v-card-title>Rejestracja</v-card-title>
          <v-card-text>
            <v-text-field v-model="login" label="Login"> </v-text-field>
            <v-text-field type="password" v-model="password" label="Hasło">
            </v-text-field>
            <v-text-field
              type="password"
              v-model="passwordConfirm"
              label="Potwierdź hasło"
              @keyup.enter="Register"
            >
            </v-text-field>
            <v-text-field v-model="email" label="Email"> </v-text-field>
            <p class="text-caption primary--text text--darken-1 text-justify">
              Adres e-mail nie jest wymagany. Podanie e-maila umożliwi
              informowanie o zbliżających się meczach do typowania oraz ułatwi
              zresetowanie hasła w przypadku, gdy je zapomnisz.
            </p>
            <v-switch
              v-model="incomingMatchesMsgAgree"
              label="Informowanie o zbliżających się meczach"
              :disabled="!email"
            ></v-switch>
          </v-card-text>
          <div class="text-right card-container">
            <v-btn
              class="primary-gradient-bg"
              outlined
              @click="Register"
              :loading="loading"
            >
              Zarejestruj
            </v-btn>
          </div>
        </v-card>
      </div>
    </v-col>
  </v-row>
</template>

<script>
import { mapActions } from "vuex";
import UserService from "@/services/UserService";

export default {
  name: "RegisterView",
  data() {
    return {
      login: "",
      email: "",
      password: "",
      passwordConfirm: "",
      incomingMatchesMsgAgree: false,
      loading: false,
    };
  },
  watch: {
    email(newVal) {
      if (!newVal) {
        this.incomingMatchesMsgAgree = false;
      }
    },
  },
  methods: {
    ...mapActions("user", ["setUser", "resetUser"]),
    ...mapActions("common", ["resetCompetitions"]),
    async Register() {
      try {
        this.loading = true;
        if (!this.$_isValid()) {
          return;
        }

        const response = await UserService.Register(
          this.login,
          this.email,
          this.password,
          this.incomingMatchesMsgAgree
        );
        if (response.status === 200 && !(response.data || {}).errors[0]) {
          this.$vToastify.customSuccess(
            "Użytkownik o nazwie " + this.login + " został utworzony"
          );

          // zaloguj automatycznie po utworzeniu konta
          const loginResponse = await UserService.LogIn(
            this.login,
            this.password
          );

          if (
            loginResponse.status === 200 &&
            !(loginResponse.data || {}).errors[0]
          ) {
            const userData = loginResponse.data.data;
            await this.setUser({
              id: userData.userId,
              login: userData.login,
              token: userData.token,
            });

            this.$vToastify.customSuccess(
              userData.login + " - zostałeś zalogowany"
            );

            await this.resetCompetitions();

            this.$router
              .push({
                name: "Home",
              })
              .catch(() => {});
          } else {
            await this.resetUser();
          }
        }
      } catch (err) {
        //
      } finally {
        this.loading = false;
      }
    },
    $_isValid() {
      if (this.login.length < 1) {
        this.$vToastify.validationError("Login nie może być pusty");
        return false;
      }
      if (this.password.length < 1) {
        this.$vToastify.validationError("Hasło nie może być puste");
        return false;
      }
      if (this.password !== this.passwordConfirm) {
        this.$vToastify.validationError("Hasła muszą być takie same");
        return false;
      }
      return true;
    },
  },
};
</script>
