<template>
  <v-dialog
    persistent
    max-width="600px"
    v-model="dialog"
    @keydown.esc="CloseDialog()"
  >
    <template v-slot:activator="{ on, attrs }">
      <v-col class="d-flex flex-column" cols="12" md="6">
        <v-btn class="primary-gradient-bg" outlined v-bind="attrs" v-on="on">
          Utwórz ligę
        </v-btn>
      </v-col>
    </template>
    <v-card>
      <v-card-title>Nowa liga prywatna</v-card-title>
      <v-card-text>
        <v-text-field
          label="Nazwa"
          v-model="leagueName"
          @keyup.enter="SubmitLeagueCreate"
        >
        </v-text-field>
        <v-select
          dense
          outlined
          multiple
          label="Rozgrywki"
          :items="competitions"
          item-text="competitionsFullName"
          item-value="competitionsId"
          v-model="competitionsSelected"
        ></v-select>
        <v-text-field
          label="Punkty za trafiony typ (ale nie wynik)"
          hide-details
          v-mask="'##'"
          v-model.number="correctBetPoints"
        ></v-text-field>
        <v-text-field
          label="Punkty za trafiony wynik"
          hide-details
          v-mask="'##'"
          v-model.number="correctResultPoints"
        ></v-text-field>
      </v-card-text>
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn class="primary-gradient-bg" outlined @click="CloseDialog()">
          Anuluj
        </v-btn>
        <v-btn
          class="primary-gradient-bg"
          outlined
          @click="SubmitLeagueCreate()"
          :loading="submitLoading"
        >
          Utwórz
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import BookmakerService from "@/services/BookmakerService";
import { mapGetters, mapActions } from "vuex";

export default {
  name: "LeagueCreateDialog",
  data: () => ({
    dialog: false,
    leagueName: "",
    competitions: [],
    competitionsSelected: [],
    correctBetPoints: 1,
    correctResultPoints: 2,
    submitLoading: false,
  }),
  async mounted() {
    await this.setCompetitions();
    this.competitions = this.getCompetitions();
  },
  methods: {
    ...mapGetters("user", ["getUserId"]),
    ...mapGetters("common", ["getCompetitions"]),
    ...mapActions("common", ["setCompetitions", "resetCompetitions"]),
    async SubmitLeagueCreate() {
      try {
        this.submitLoading = true;
        if (!this.$_isValid()) {
          return;
        }

        const response = await BookmakerService.LeagueCreate({
          Name: this.leagueName,
          InsertUserId: this.getUserId(),
          CompetitionsIds: this.competitionsSelected,
          CorrectBetPoints: this.correctBetPoints,
          CorrectResultPoints: this.correctResultPoints,
        });

        if (response.status === 200 && !(response.data || {}).errors[0]) {
          this.$vToastify.customSuccess("Liga została utworzona");
          await this.resetCompetitions();
          this.CloseDialog();
          this.$emit("league-created");
        }
      } catch (err) {
        return;
      } finally {
        this.submitLoading = false;
      }
    },
    CloseDialog() {
      this.dialog = false;
      this.leagueName = "";
      this.competitionsSelected = [];
      this.$emit("input");
    },
    $_isValid() {
      if (!this.leagueName) {
        this.$vToastify.validationError("Wprowadź nazwę ligi");
        return false;
      }
      if (this.leagueName.length > 50) {
        this.$vToastify.validationError(
          "Nazwa ligi nie może przekraczać 50 znaków"
        );
        return false;
      }

      if (!this.competitionsSelected[0]) {
        this.$vToastify.validationError("Wybierz rozgrywki do typowania");
        return false;
      }

      if (this.correctBetPoints === "" || this.correctResultPoints === "") {
        this.$vToastify.validationError("Musisz ustalić punktację");
        return false;
      }
      if (this.correctBetPoints < 0 || this.correctResultPoints < 0) {
        this.$vToastify.validationError("Punktacja nie może być ujemna");
        return false;
      }

      return true;
    },
  },
};
</script>
