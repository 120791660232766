<template>
  <v-row justify="center">
    <v-col cols="12" md="6">
      <div>
        <v-card>
          <v-card-title>Edycja profilu</v-card-title>
          <v-card-text>
            <v-text-field v-model="user.login" label="Login"> </v-text-field>
            <v-text-field v-model="user.email" label="Email"> </v-text-field>
            <p class="text-caption primary--text text--darken-1 text-justify">
              Adres e-mail nie jest wymagany. Podanie e-maila umożliwi
              informowanie o zbliżających się meczach do typowania oraz ułatwi
              zresetowanie hasła w przypadku, gdy je zapomnisz.
            </p>
            <v-switch
              v-model="user.incomingMatchesMsgAgree"
              label="Informowanie o zbliżających się meczach"
              :disabled="!user.email"
            ></v-switch>
          </v-card-text>
          <div class="text-right card-container">
            <v-btn
              class="primary-gradient-bg"
              outlined
              @click="SaveProfile"
              :loading="loading"
            >
              Zapisz
            </v-btn>
          </div>
        </v-card>
      </div>
    </v-col>
  </v-row>
</template>

<script>
import { mapActions } from "vuex";
import UserService from "@/services/UserService";

export default {
  name: "UserProfileView",
  props: {
    userId: {
      type: String,
    },
  },
  data() {
    return {
      user: {},
      loading: false,
    };
  },
  async mounted() {
    await this.$_setUserData();
  },
  watch: {
    "user.email"(newVal) {
      if (!newVal) {
        this.user.incomingMatchesMsgAgree = false;
      }
    },
  },
  methods: {
    ...mapActions("user", ["updateUserLogin"]),
    async SaveProfile() {
      try {
        this.loading = true;

        const response = await UserService.UpdateProfile(
          this.user.login,
          this.user.email,
          this.user.incomingMatchesMsgAgree
        );

        if (response.status === 200 && !(response.data || {}).errors[0]) {
          this.updateUserLogin({ login: this.user.login });
          this.$vToastify.customSuccess("Zapisano zmiany");
        }
      } catch (err) {
        //
      } finally {
        this.loading = false;
      }
    },
    async $_setUserData() {
      try {
        const response = await UserService.GetUser(this.userId);
        const result = response.data;
        if (result.success) {
          this.user = result.data;
        }
      } catch (err) {
        //
      }
    },
  },
};
</script>
