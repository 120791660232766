<template>
  <v-row>
    <v-col cols="12">
      <div class="text-h5 mb-5">Ligi typerów</div>
      <v-card>
        <v-row>
          <LeagueCreateDialog
            @league-created="SetLeaguesData()"
          ></LeagueCreateDialog>
          <LeagueJoinDialog
            @league-joined="SetLeaguesData()"
          ></LeagueJoinDialog>
        </v-row>
        <v-card-title class="mt-5">
          Ligi prywatne
          <v-tooltip bottom>
            <template v-slot:activator="{ on, attrs }">
              <v-icon v-bind="attrs" v-on="on" class="ml-1">
                fas fa-info-circle
              </v-icon>
            </template>
            <span>
              Ligi prywatne tworzą użytkownicy aplikacji. Możesz założyć swoją
              ligę i zaprosić do niej znajomych lub poprosić znajomego o kod i
              dołączyć do jego ligi
            </span>
          </v-tooltip>
        </v-card-title>
        <v-data-table
          :headers="headers"
          :items="privateLeagues"
          :loading="loading"
          hide-default-footer
          disable-sort
          class="elevation-1"
          :items-per-page="-1"
          mobile-breakpoint="0"
        >
          <template v-slot:[`item.options`]="{ item }">
            <v-tooltip bottom>
              <template v-slot:activator="{ on, attrs }">
                <v-btn
                  v-bind="attrs"
                  v-on="on"
                  @click="ShowLeagueRank(item)"
                  icon
                >
                  <v-icon>fas fa-poll</v-icon>
                </v-btn>
              </template>
              <span> Ranking </span>
            </v-tooltip>
            <v-menu offset-y bottom rounded="lg">
              <template v-slot:activator="{ on, attrs }">
                <v-btn icon>
                  <v-icon v-bind="attrs" v-on="on">fas fa-ellipsis-v</v-icon>
                </v-btn>
              </template>
              <v-list>
                <v-list-item @click="CopyLeagueCode(item.leagueId)">
                  <v-list-item-title>Skopiuj kod ligi</v-list-item-title>
                </v-list-item>
                <base-confirm
                  :message="
                    'Czy jesteś pewien że chcesz opuścić ligę ' +
                    item.leagueName +
                    '?'
                  "
                  @confirm="LeaveLeague(item.leagueId)"
                >
                  <template v-slot:default="{ on }">
                    <v-list-item @click="() => {}">
                      <v-list-item-title v-on="on">
                        Opuść ligę
                      </v-list-item-title>
                    </v-list-item>
                  </template>
                </base-confirm>
              </v-list>
            </v-menu>
          </template>
        </v-data-table>
      </v-card>
    </v-col>
  </v-row>
</template>

<script>
import LeagueCreateDialog from "@/components/bookmaker/LeagueCreateDialog.vue";
import LeagueJoinDialog from "@/components/bookmaker/LeagueJoinDialog.vue";
import BaseConfirm from "@/components/base/BaseConfirm.vue";
import UserService from "@/services/UserService";

export default {
  name: "BookmakerLeaguesView",
  components: { LeagueCreateDialog, LeagueJoinDialog, BaseConfirm },
  data() {
    return {
      headers: [
        { text: "Liga", value: "leagueName", align: "start" },
        { text: "Pozycja", value: "entryRank", align: "start" },
        { value: "options", align: "end" },
      ],
      leagues: [],
      loading: false,
      selectedItem: undefined,
      showDeleteConfirm: false,
    };
  },
  computed: {
    privateLeagues() {
      return this.leagues.filter((x) => x.isPrivate);
    },
    globalLeague() {
      return [this.leagues.find((x) => !x.isPrivate)];
    },
  },
  mounted() {
    this.SetLeaguesData();
  },
  methods: {
    async SetLeaguesData() {
      try {
        this.loading = true;
        const response = await UserService.GetBookmakerLeaguesJoined();

        if (response.status === 200 && !(response.data || {}).errors[0]) {
          this.leagues = response.data.data;
        }
      } catch (err) {
        return;
      } finally {
        this.loading = false;
      }
    },
    async CopyLeagueCode(code) {
      if (window.isSecureContext && navigator.clipboard) {
        await window.navigator.clipboard.writeText(code);
      } else {
        this.$_unsecuredCopyToClipboard(code);
      }
      this.$vToastify.customSuccess("Kod ligi został skopiowany");
    },
    // TODO hak - zrobić to lepiej
    // https://stackoverflow.com/questions/71873824/copy-text-to-clipboard-cannot-read-properties-of-undefined-reading-writetext
    $_unsecuredCopyToClipboard(text) {
      const textArea = document.createElement("textarea");
      textArea.value = text;
      document.body.appendChild(textArea);
      textArea.select();
      try {
        document.execCommand("copy");
      } catch (err) {
        console.error("Unable to copy to clipboard", err);
      }
      document.body.removeChild(textArea);
    },
    async LeaveLeague(leagueId) {
      try {
        const response = await UserService.BookmakerLeagueLeave(leagueId);

        if (response.status === 200 && !(response.data || {}).errors[0]) {
          this.$vToastify.customSuccess("Opuściłeś ligę");
          this.SetLeaguesData();
        }
      } catch (err) {
        return;
      }
    },
    async ShowLeagueRank(row) {
      this.$router
        .push({
          name: "BookmakerLeague",
          params: { leagueId: row.leagueId },
        })
        .catch(() => {});
    },
  },
};
</script>

<style scoped>
.row-pointer >>> tbody tr :hover {
  cursor: pointer;
}
</style>
