<template>
  <v-row justify="center">
    <v-col cols="12" md="6" lg="4">
      <div>
        <v-card>
          <v-card-title>Resetowanie hasła</v-card-title>
          <v-card-text>
            <v-text-field v-model="login" label="Login"> </v-text-field>
            <v-text-field v-model="email" label="Email"> </v-text-field>
          </v-card-text>
          <div class="text-right card-container">
            <v-btn
              class="primary-gradient-bg"
              outlined
              @click="ResetPassword"
              :loading="loading"
            >
              Resetuj hasło
            </v-btn>
          </div>
        </v-card>
      </div>
    </v-col>
  </v-row>
</template>

<script>
import UserService from "@/services/UserService";

export default {
  name: "ResetPasswordView",
  data() {
    return {
      login: "",
      email: "",
      loading: false,
    };
  },
  methods: {
    async ResetPassword() {
      try {
        this.loading = true;
        if (!this.$_isValid()) {
          return;
        }

        const response = await UserService.ResetPassword(
          this.login,
          this.email
        );
        if (response.status === 200 && !(response.data || {}).errors[0]) {
          this.$vToastify.customSuccess(
            "Twoje hasło zostało zresetowane. Nowo wygenerowane hasło zostało wysłane na Twój adres email."
          );

          this.$router
            .push({
              name: "Login",
            })
            .catch(() => {});
        }
      } catch (err) {
        //
      } finally {
        this.loading = false;
      }
    },
    $_isValid() {
      if (this.login.length < 1) {
        this.$vToastify.validationError("Login nie może być pusty");
        return false;
      }
      if (this.email.length < 1) {
        this.$vToastify.validationError("Adres e-mail nie może być pusty");
        return false;
      }
      return true;
    },
  },
};
</script>
