var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-row',[_c('v-col',{attrs:{"cols":"12"}},[_c('div',{staticClass:"text-h5 mb-5"},[_vm._v("Ligi typerów")]),_c('v-card',[_c('v-row',[_c('LeagueCreateDialog',{on:{"league-created":function($event){return _vm.SetLeaguesData()}}}),_c('LeagueJoinDialog',{on:{"league-joined":function($event){return _vm.SetLeaguesData()}}})],1),_c('v-card-title',{staticClass:"mt-5"},[_vm._v(" Ligi prywatne "),_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-icon',_vm._g(_vm._b({staticClass:"ml-1"},'v-icon',attrs,false),on),[_vm._v(" fas fa-info-circle ")])]}}])},[_c('span',[_vm._v(" Ligi prywatne tworzą użytkownicy aplikacji. Możesz założyć swoją ligę i zaprosić do niej znajomych lub poprosić znajomego o kod i dołączyć do jego ligi ")])])],1),_c('v-data-table',{staticClass:"elevation-1",attrs:{"headers":_vm.headers,"items":_vm.privateLeagues,"loading":_vm.loading,"hide-default-footer":"","disable-sort":"","items-per-page":-1,"mobile-breakpoint":"0"},scopedSlots:_vm._u([{key:"item.options",fn:function(ref){
var item = ref.item;
return [_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({attrs:{"icon":""},on:{"click":function($event){return _vm.ShowLeagueRank(item)}}},'v-btn',attrs,false),on),[_c('v-icon',[_vm._v("fas fa-poll")])],1)]}}],null,true)},[_c('span',[_vm._v(" Ranking ")])]),_c('v-menu',{attrs:{"offset-y":"","bottom":"","rounded":"lg"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',{attrs:{"icon":""}},[_c('v-icon',_vm._g(_vm._b({},'v-icon',attrs,false),on),[_vm._v("fas fa-ellipsis-v")])],1)]}}],null,true)},[_c('v-list',[_c('v-list-item',{on:{"click":function($event){return _vm.CopyLeagueCode(item.leagueId)}}},[_c('v-list-item-title',[_vm._v("Skopiuj kod ligi")])],1),_c('base-confirm',{attrs:{"message":'Czy jesteś pewien że chcesz opuścić ligę ' +
                  item.leagueName +
                  '?'},on:{"confirm":function($event){return _vm.LeaveLeague(item.leagueId)}},scopedSlots:_vm._u([{key:"default",fn:function(ref){
                  var on = ref.on;
return [_c('v-list-item',{on:{"click":function () {}}},[_c('v-list-item-title',_vm._g({},on),[_vm._v(" Opuść ligę ")])],1)]}}],null,true)})],1)],1)]}}],null,true)})],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }